import React from 'react';
import Appearance from 'styles/Appearance.js';
import { NavigationSectionHeader } from 'managers/Components.js';
import RichTextEditor from 'views/RichTextEditor';

export const PrivacyPolicy = ({ dealership, omnishield, utils }) => {

    const getAppName = () => {
        if(!omnishield) {
            return '...';
        }
        if(omnishield.enabled === false) {
            return 'OmniShield App';
        }
        if(omnishield.android.app_name === omnishield.ios.app_name) {
            return `${omnishield.ios.app_name} App`;
        }
        return `${omnishield.ios.app_name} App for iOS and ${omnishield.android.app_name} App for Android`;
    }

    const getCustomPolicyContent = () => {

        let { blocks = [] } = dealership.privacy_policy || {};
        if(blocks?.length > 0 && blocks[0]?.text.length > 0) {
            return (
                <RichTextEditor 
                content={dealership.privacy_policy}
                utils={utils} 
                viewer={true} />
            )
        }
    }

    const getEmailAddress = () => {
        if(!omnishield) {
            return '...';
        }
        return omnishield.enabled === true ? dealership.email_address : 'info@homesafenetwork.com';
    }

    const getOmniShieldPrivacyPolicy = () => {

        let sections = [{
            title: `${getAppName()} Privacy Statement.`,
            values: [
                `This Privacy Statement for the ${getAppName()} (the “App”) is provided by Global Health & Safety LLC (“Global”), manufacturer of the products to which the App pertains. Those products include combination smoke and heat-activated alarms, standalone heat alarms, carbon monoxide alarms, water sensors, and bed shakers (the “Products”), all of which communicate with one another via radio frequency. Some or all of these Products have been installed in your home by an authorized OmniShield dealer.`
            ]
        },{
            title: 'Personal Information Collected by the App.',
            values: [
                `The App collects the following personal information from you: your name; the residential location at which the Products purchased by you are installed; your cell phone number; and your email address. The App also collects the following personal information as to up to seven other persons who have consented to your including such personal information in the App (“Your Contacts”): such persons' names, cell phone numbers, and in some instances email addresses.`
            ]
        },{
            title: 'Use Made of Personal Information.',
            values: [
                `All of the personal information collected by the App is solely used for purposes directly associated with the App. The "Home Address" page of the App lists your residential address, at which the Products are installed. That item of personal information is not shared with any person or company.`,
                `The "Contacts" page of the App contains your name, your cell phone number, and (if you have provided it) your email address. The Contacts page also contains the names, cell phone numbers, and potentially the email addresses of Your Contacts.`,
                `We do not sell such names, cell phone numbers, and email addresses to anyone. We share such personal information only with businesses with which we contract (“Third Parties”) in order to provide you the services made available by the App. Those Third Parties are responsible, when one or more Alarms in your home detects a condition of concern, for causing text messages and potentially email messages to be sent to you and to Your Contacts. That is the only reason we share personal information with Third Parties. If we did not share personal information with such Third Parties, the App would not work, and neither you nor Your Contacts would receive text alerts or email alerts as to a condition of concern which has arisen at your home.`
            ]
        },{
            title: 'Changing Personal Information.',
            values: [
                `If the cell phone number or email address of you or any of Your Contacts changes, you need only enter that change in the App. Alerts will thereafter be sent to the changed cell phone number and/or email address.`
            ]
        },{
            title: 'Your Control Over Personal Information.',
            values: [
                `You entirely control the personal information we possess and that we share with Third Parties. If you no longer wish us to have your personal information, all you need to do is inform us by email sent to ${getEmailAddress()}. We will delete your personal information, as well as the personal information of Your Contacts, and notify the Third Parties that they too are to delete such personal information.`,
                `If one of Your Contacts no longer wishes us to have his or her personal information, all such person need do is so inform us by email sent to ${getEmailAddress()}, identifying you in the email as the OmniShield product owner for whom he or she has been listed as a Contact. We will delete such person's personal information and notify the Third Parties that they are also to do so.`
            ]
        },{
            title: 'Notification to Your Contacts.',
            values: [
                `You agree to inform all of Your Contacts of their need to download the App and view this Privacy Statement, to make sure they understand the provisions herein applicable to them.`
            ]
        },{
            title: 'Security.',
            values: [
                `We take precautions to protect and securely maintain all of the personal information collected by the App. Access to such personal information at Global is severely restricted and available only to the person or persons whose work responsibilities include overseeing the App. All transmissions by us to Third Parties, containing personal information, are encrypted to ensure that such personal information is protected.`
            ]
        },{
            title: 'Questions.',
            values: [
                `If you have any questions concerning this privacy policy or the App, please direct them in the first instance to info@homesafenetwork.com.`
            ]
        }]

        return sections.map((section, index) => {
            return (
                <div 
                key={index}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: index !== sections.length - 1 ? 36 : 0,
                    width: '100%'
                }}>
                    <span style={{
                        color: Appearance.colors.text(),
                        display: 'block',
                        fontWeight: 600,
                        fontSize: 18,
                        lineHeight: 1.2,
                        marginBottom: 12
                    }}>{section.title}</span>
                    {section.values.map((value, index, values) => {
                        return (
                            <span 
                            key={index}
                            style={{
                                color: Appearance.colors.text(),
                                display: 'block',
                                fontWeight: 400,
                                fontSize: 16,
                                lineHeight: '24px',
                                marginBottom: index !== values.length - 1 ? 8 : 0
                            }}>{value}</span>
                        )
                    })}
                </div>
            )
        });
    }

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            width:  '100%'
        }}>
            <NavigationSectionHeader
            dealership={dealership} 
            identifier={'privacy'}
            title={'Privacy Policy'} 
            utils={utils} />
            <div style={{
                padding: '5vw',
                maxWidth: 1250,
                width: '100%'
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: 36,
                    padding: 36,
                    width: '100%'
                }}>
                    {getOmniShieldPrivacyPolicy()}
                </div>
                {getCustomPolicyContent()}
            </div>
        </div>
    )
}