import React from 'react';

import Appearance from 'styles/Appearance.js';
import DatePickerField from 'views/DatePickerField.js';
import ImagePickerField from 'views/ImagePickerField.js';
import { LayerItem } from 'structure/Layer.js';
import ListField from 'views/ListField.js';
import TextField from 'views/TextField.js';

export const AltFieldItem = ({ content, item, lastItem = false, utils }) => {

    const getComponent = item => {

        // determine if a custom component was provided
        if(content) {
            return content;
        }

        // determine which type of component to prepare
        let { component, icon, items, loading, onChange, onValidate, props = {}, value } = item;
        switch(component) {

            case 'bool_list':
            return (
                <ListField
                items={[{
                    id: 'yes',
                    title: 'Yes'
                },{
                    id: 'no',
                    title: 'No'
                }]}
                onChange={item => onChange(item ? item.id === 'yes' : null)}
                value={typeof(value) === 'boolean' ? (value ? 'Yes' : 'No') : null}
                {...props} />
            );

            case 'date_picker':
            return (
                <DatePickerField
                utils={utils}
                icon={icon}
                value={value}
                selected={value}
                onValidate={onValidate}
                onDateChange={onChange}
                {...props} />
            )

            case 'image_picker':
            return (
                <ImagePickerField
                utils={utils}
                value={value}
                onChange={onChange}
                {...props} />
            )


            case 'list':
            return (
                <ListField
                items={items}
                onChange={onChange}
                value={value}
                {...props} />
            )

            case 'textfield':
            return (
                <TextField
                icon={icon}
                value={value}
                loading={loading}
                onValidate={onValidate}
                onChange={onChange}
                {...props} />
            )

            case 'textview':
            return (
                <TextField
                icon={icon}
                value={value}
                loading={loading}
                expandWithText={true}
                onValidate={onValidate}
                onChange={onChange}
                {...props} />
            )

            default:
            return null;
        }
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: lastItem === false ? 12 : 0,
            width: '100%'
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row'
            }}>
                {item.required !== false && (item.value === null || item.value === undefined) && (
                    <div style={{
                        backgroundColor: Appearance.colors.red,
                        borderRadius: 2.5,
                        height: 5,
                        marginRight: 8,
                        overflow: 'hidden',
                        width: 5
                    }} />
                )}
                <span style={{
                    ...Appearance.textStyles.subHeader(),
                    display: 'block',
                    marginBottom: 4
                }}>{item.title}</span>
            </div>
            {getComponent(item)}
        </div>
    )
}

const AltFieldMapper = ({ fields, utils }) => {

    return fields.filter(section => {
        return section.visible !== false
    }).map((section, index, sections) => {
        return (
            <LayerItem
            collapsed={section.collapsed !== undefined ? section.collapsed : null}
            key={index}
            lastItem={typeof(section.lastItem) === 'boolean' ? section.lastItem : index === sections.length - 1}
            rightContent={section.rightContent}
            title={section.title}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    padding: 12,
                    overflow: 'visible',
                    width: '100%',
                    ...section.style
                }}>
                    {section.items && section.items.filter(item => {
                        return item.visible !== false;
                    }).map((item, index, items) => {
                        return (
                            <AltFieldItem 
                            item={item} 
                            key={index} 
                            lastItem={index === items.length - 1} 
                            utils={utils}/>   
                        )
                    })}
                </div>
            </LayerItem>
        )
    });
}

export const validateRequiredFields = async get => {
    return new Promise((resolve, reject) => {
        let items = get().reduce((array, field) => {
            return array.concat(field.items);
        }, []);
        let required = items.find(item => {
            if(item.required === false) {
                return false;
            }
            return item.value === null || item.value === undefined;
        });
        if(required) {
            let error = new Error(`One or more required fields are incomplete. Please fill out the "${required.title.toLowerCase()}" before moving on`);
            reject(error);
            return;
        }
        resolve();
    })
}

export default AltFieldMapper;
