import React, { useEffect, useRef, useState } from 'react';

import AltFieldMapper, { AltFieldItem, validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { NavigationSectionHeader } from 'managers/Components.js';
import QueryString from 'query-string';
import Request from 'files/Request.js';
import Utils from 'files/Utils.js';

export const CertificationTestOnboarding = ({ dealership, utils }) => {

    const stripeProps = useRef(null);

    const [loading, setLoading] = useState(true);
    const [paymentMethodCompleted, setPaymentMethodCompleted] = useState(false);
    const [user, setUser] = useState(null);

    const onClearClick = () => {
        utils.alert.show({
            title: 'Clear Progress',
            message: 'Are you sure you want to clear all fields and start over?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    let element = stripeProps.current.elements.getElement(CardElement);
                    element.clear();
                }
            }
        });
    }

    const onSubmitClick = async () => {
        try {

            // prevent moving forward if method input is not complete
            if(paymentMethodCompleted === false) {
                throw new Error('Please fill out your card number, expiration date, and cvc code to continue.');
            }

            // generate card token for request
            setLoading('submit');
            let element = stripeProps.current.elements.getElement(CardElement);
            let { token } = await stripeProps.current.stripe.createToken(element);

            // send additional request to server to process test payment
            console.log({
                ...user,
                card_token: token?.id,
                dealership_id: dealership.id,
                type: 'new_certification_test_payment'
            })
            await Request.post(utils, '/payments/', {
                ...user,
                card_token: token?.id,
                dealership_id: dealership.id,
                type: 'new_certification_test_payment'
            });

            // end loading and clear payment method field
            setLoading(false);
            element.clear();

            // show confirmation alert and update page to show links for trainees
            utils.alert.show({
                title: 'All Done!',
                message: `Your ${utils.dealership.get().name} payment has been processed. Check your inbox for your email confirmation.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue processing your payment. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getContent = () => {
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '5vw',
                width: '100%'
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: Utils.isMobile() ? 24 : 0,
                    maxWidth: 1250,
                    padding: Utils.isMobile() ? 24 : '3vw',
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <img 
                    src={'images/vector-sensor-group.png'}
                    style={{
                        height: 'auto',
                        marginBottom: Utils.isMobile() === true ? 24 : 36,
                        maxWidth: 500,
                        objectFit: 'contain',
                        width: '100%'
                    }} />
                    <span style={{
                        color: Appearance.colors.text(),
                        fontFamily: Appearance.fonts.getFamilyForWeight(400),
                        fontSize: 18,
                        maxWidth: 750
                    }}>{`${utils.dealership.get().name} provides safety advisor certification testing for all newly hired safety advisors. A per-test fee of $10 is required to prepare the cetification test for your account. Please provide your credit or debit card details below to continue.`}</span>
                </div>
                {user !== null && (
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: 1250,
                        padding: Utils.isMobile() ? 0 : '3vw',
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        <div style={{
                            display: 'block',
                            maxWidth: 750,
                            width: '100%'
                        }}>
                            {getStripeCardComponent()}
                            <div style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: 24,
                                width: '100%'
                            }}>
                                <div style={{
                                    paddingRight: 4,
                                    width: '50%'
                                }}>
                                    <Button 
                                    color={'dark'}
                                    label={'Clear'}
                                    onClick={onClearClick} 
                                    type={'large'} />
                                </div>
                               
                                <div style={{
                                    paddingLeft: 4,
                                    width: '50%'
                                }}>
                                    <Button 
                                    color={'primary'}
                                    label={'Submit'}
                                    loading={loading === 'submit'}
                                    onClick={onSubmitClick} 
                                    type={'large'} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const getStripeCardComponent = () => {
        return user !== null && (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
            }}>
                <div style={{
                    padding: Utils.isMobile() ? 24 : 36,
                    width: '100%'
                }}>
                    <AltFieldItem 
                    content={(
                        <Elements stripe={utils.stripe}>
                            <ElementsConsumer>
                                {props => {
                                    stripeProps.current = props;
                                    return (
                                        <div style={{
                                            backgroundColor: Appearance.colors.textField(),
                                            border: `1px solid ${Appearance.colors.divider()}`,
                                            borderRadius: 8,
                                            padding: '0.5275rem 0.75rem',
                                            width: '100%'
                                        }}>
                                            <CardElement
                                            onChange={({ complete }) => setPaymentMethodCompleted(complete)}
                                            options={{
                                                hidePostalCode: true,
                                                style: {
                                                    invalid: {
                                                        color: Appearance.colors.red,
                                                    },
                                                    base: {
                                                        backgroundColor: Appearance.colors.textField(),
                                                        color: Appearance.colors.text(),
                                                        fontSize: '14px',
                                                        fontWeight: 500,
                                                        '::placeholder': {
                                                            color: Appearance.colors.subText(),
                                                        },
                                                    }
                                                }
                                            }} />
                                        </div>
                                    )
                                }}
                            </ElementsConsumer>
                        </Elements>
                    )} 
                    item={{
                        required: true,
                        title: 'Credit or Debit Card',
                        value: paymentMethodCompleted || null
                    }}
                    lastItem={true}
                    utils={utils} />
                </div>
            </div>
        )
    }

    const setupUserContent = async () => {
        try {

            // get token from request query string
            let { token } = QueryString.parse(window.location.search);
            if(!token) {
                throw new Error('an access token is required when starting the onboarding process.');
            }

            // set loading flag and submit request to server
            setLoading(true);
            let { user } = await Request.post(utils, '/users/', {
                origin: 'replicated_website',
                refresh_token: token,
                type: 'login'
            });

            // end loading and update local state with user account details
            setLoading(false);
            setUser({ 
                token: user.token,
                user_id: user.user_id
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the onboarding process. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setupUserContent();
    }, []);

    return (
        <>
        <NavigationSectionHeader
        dealership={dealership} 
        identifier={'certification_test_onboarding'}
        title={'Certification Test Onboarding'} 
        utils={utils} />
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            {getContent()}
        </div>
        </>
    )
}

export const TraineeSignup = ({ dealership, utils }) => {

    const account = useRef({});
    const stripeProps = useRef(null);

    const [edits, setEdits] = useState({});
    const [enabled, setEnabled] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paymentMethodCompleted, setPaymentMethodCompleted] = useState(false);

    const onClearClick = () => {
        utils.alert.show({
            title: 'Clear Progress',
            message: 'Are you sure you want to clear all fields and start over?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setEdits({});
                    return;
                }
            }
        });
    }

    const onSubmitClick = async () => {
        try {

            // verify that all required fields were completed
            await validateRequiredFields(getFields);

            // verify that both password fields match 
            if(edits.password !== edits.password_confirm) {
                throw new Error('The passwords that you provided do not match');
            }

            // prevent moving forward if method input is not complete
            let shouldRequestPayment = dealership.trainee_signup.setup_testcom_account === true;
            if(shouldRequestPayment === true && paymentMethodCompleted === false) {
                throw new Error('Please fill out your card number, expiration date, and cvc code to continue.');
            }

            // generate card token and add parameters to request payload if applicable
            setLoading(true);
            let element = shouldRequestPayment ? stripeProps.current.elements.getElement(CardElement) : null;
            let { token } = shouldRequestPayment ? await stripeProps.current.stripe.createToken(element) : {};

            // set loading flag and send request to server to create user account if applicable
            if(!account.current?.user_id) { 
                account.current = await Request.post(utils, '/users/', {
                    ...edits,
                    dealership_id: dealership.id,
                    type: 'trainee_signup'
                });
            }

            // send additional request to server to process test payment if applciable
            if(shouldRequestPayment === true) {
                await Request.post(utils, '/payments/', {
                    card_token: token?.id,
                    dealership_id: dealership.id,
                    token: account.current.token,
                    type: 'new_certification_test_payment',
                    user_id: account.current.user_id
                });
            }

            // end loading and clear fields for new edits
            setLoading(false);
            setEdits({});

            // clear payment method field if applicable
            if(element) {
                element.clear();
            }

            // show confirmation alert and update page to show links for trainees
            utils.alert.show({
                title: 'All Done!',
                message: `Your ${utils.dealership.get().name} account has been created and is ready to use. Check your inbox for your email confirmation.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating your account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setEdits(edits => ({ ...edits, ...props }));
    }

    const getContent = () => {

        // show error message if dealership has disabled trainee signup links
        if(enabled === false) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '5vw',
                    width: '100%'
                }}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: 24,
                        maxWidth: 1250,
                        padding: Utils.isMobile() ? 24 : '3vw',
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        <img 
                        src={'images/vector-sensor-group.png'}
                        style={{
                            height: 'auto',
                            marginBottom: Utils.isMobile() === true ? 24 : 36,
                            maxWidth: 500,
                            objectFit: 'contain',
                            width: '100%'
                        }} />
                        <span style={{
                            color: Appearance.colors.text(),
                            fontFamily: Appearance.fonts.getFamilyForWeight(400),
                            fontSize: 18,
                            maxWidth: 575
                        }}>{`${utils.dealership.get().name} is not accepting new trainee signups at this time. Please contact support if you believe this was done in error.`}</span>
                    </div>
                </div>
            )
        }

        // show signup form if dealership has enabled trainee signup links
        if(enabled === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '5vw',
                    width: '100%'
                }}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: Utils.isMobile() ? 24 : 0,
                        maxWidth: 1250,
                        padding: Utils.isMobile() ? 24 : '3vw',
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        <img 
                        src={'images/vector-sensor-group.png'}
                        style={{
                            height: 'auto',
                            marginBottom: Utils.isMobile() === true ? 24 : 36,
                            maxWidth: 500,
                            objectFit: 'contain',
                            width: '100%'
                        }} />
                        <span style={{
                            color: Appearance.colors.text(),
                            fontFamily: Appearance.fonts.getFamilyForWeight(400),
                            fontSize: 18,
                            maxWidth: 750
                        }}>{`Please use the form below to create your account for ${utils.dealership.get().name}. A ${utils.dealership.get().name} account gives you premium access to a library of training content geared towards preparing you for your future in direct sales.`}</span>
                    </div>
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: 1250,
                        padding: Utils.isMobile() ? 0 : '3vw',
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        <div style={{
                            display: 'block',
                            maxWidth: 750,
                            width: '100%'
                        }}>
                            <AltFieldMapper
                            fields={getFields()}
                            utils={utils} />
                            {dealership.trainee_signup.setup_testcom_account && getStripeCardComponent()}
                            <div style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: 24,
                                width: '100%'
                            }}>
                                <div style={{
                                    paddingRight: 4,
                                    width: '50%'
                                }}>
                                    <Button 
                                    color={'dark'}
                                    label={'Clear'}
                                    onClick={onClearClick} 
                                    type={'large'} />
                                </div>
                               
                                <div style={{
                                    paddingLeft: 4,
                                    width: '50%'
                                }}>
                                    <Button 
                                    color={'primary'}
                                    label={'Submit'}
                                    loading={loading}
                                    onClick={onSubmitClick} 
                                    type={'large'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const getFields = () => {
        return [{
            key: 'account',
            style: { padding: Utils.isMobile() ? 24 : 36 },
            items: [{
                component: 'textfield',
                key: 'username',
                onChange: text => onUpdateTarget({ username: text }),
                title: 'Username',
                value: edits.username
            },{
                component: 'textfield',
                key: 'password',
                onChange: text => onUpdateTarget({ password: text }),
                props: {
                    autoComplete: 'new-password',
                    isSecure: true
                },
                title: 'Password',
                value: edits.password
            },{
                component: 'textfield',
                key: 'password_confirm',
                onChange: text => onUpdateTarget({ password_confirm: text }),
                props: {
                    autoComplete: 'new-password',
                    isSecure: true
                },
                title: 'Confirm Password',
                value: edits.password_confirm
            }]
        },{
            key: 'details',
            lastItem: false,
            style: { padding: Utils.isMobile() ? 24 : 36 },
            items: [{
                component: 'textfield',
                key: 'first_name',
                onChange: text => onUpdateTarget({ first_name: text }),
                props: {
                    autoComplete: 'given-name'
                },
                title: 'First Name',
                value: edits.first_name
            },{
                component: 'textfield',
                key: 'last_name',
                onChange: text => onUpdateTarget({ last_name: text }),
                props: {
                    autoComplete: 'family-name'
                },
                title: 'Last Name',
                value: edits.last_name
            },{
                component: 'textfield',
                key: 'email_address',
                onChange: text => onUpdateTarget({ email_address: text }),
                props: {
                    autoComplete: 'email'
                },
                title: 'Email Address',
                value: edits.email_address
            },{
                component: 'textfield',
                key: 'phone_number',
                onChange: text => onUpdateTarget({ phone_number: text }),
                props: {
                    autoComplete: 'tel',
                    format: 'phone_number'
                },
                title: 'Phone Number',
                value: edits.phone_number
            }]
        }];
    }

    const getStripeCardComponent = () => {
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
            }}>
                <span style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`,
                    color: Appearance.colors.text(),
                    display: 'block',
                    fontFamily: Appearance.fonts.getFamilyForWeight(400),
                    fontSize: 16,
                    padding: Utils.isMobile() ? 24 : 36,
                    width: '100%'
                }}>{`${utils.dealership.get().name} provides safety advisor certification testing for all newly hired safety advisors. A per-test fee of $10 is required to prepare the cetification test for your account. Please provide your credit or debit card details below to continue.`}</span>

                <div style={{
                    padding: Utils.isMobile() ? 24 : 36,
                    width: '100%'
                }}>
                    <AltFieldItem 
                    content={(
                        <Elements stripe={utils.stripe}>
                            <ElementsConsumer>
                                {props => {
                                    stripeProps.current = props;
                                    return (
                                        <div style={{
                                            backgroundColor: Appearance.colors.textField(),
                                            border: `1px solid ${Appearance.colors.divider()}`,
                                            borderRadius: 8,
                                            padding: '0.5275rem 0.75rem',
                                            width: '100%'
                                        }}>
                                            <CardElement
                                            onChange={({ complete }) => setPaymentMethodCompleted(complete)}
                                            options={{
                                                hidePostalCode: true,
                                                style: {
                                                    invalid: {
                                                        color: Appearance.colors.red,
                                                    },
                                                    base: {
                                                        backgroundColor: Appearance.colors.textField(),
                                                        color: Appearance.colors.text(),
                                                        fontSize: '14px',
                                                        fontWeight: 500,
                                                        '::placeholder': {
                                                            color: Appearance.colors.subText(),
                                                        },
                                                    }
                                                }
                                            }} />
                                        </div>
                                    )
                                }}
                            </ElementsConsumer>
                        </Elements>
                    )} 
                    item={{
                        required: true,
                        title: 'Credit or Debit Card',
                        value: paymentMethodCompleted || null
                    }}
                    lastItem={true}
                    utils={utils} />
                </div>
            </div>
        )
    }

    const validateTraineeSignupLinkStatus = () => {
        let dealership = utils.dealership.get();
        setEnabled(dealership.trainee_signup.enabled);
    }

    useEffect(() => {
        validateTraineeSignupLinkStatus();
    }, []);

    return (
        <>
        <NavigationSectionHeader
        dealership={dealership} 
        identifier={'trainee_signup'}
        title={'Trainee Signup'} 
        utils={utils} />
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            {getContent()}
        </div>
        </>
    )
}